import React from "react";
import useAutoFormField from "atom/autoform/useAutoFormField";
import { TextareaProps } from "atom/textarea/textareaComponents";
import TextArea from "molecule/textarea/Textarea";

function AutoFormTextAreaField(
  componentProps: TextareaProps & { name: string }
) {
  const [autoFormFieldProps] = useAutoFormField<TextareaProps>(
    componentProps.name,
    "textarea"
  );
  if (!autoFormFieldProps || !autoFormFieldProps.name) return null;

  return <TextArea {...componentProps} {...autoFormFieldProps} />;
}

export default AutoFormTextAreaField;
